import React from "react"

import { Container, Text, Div, Image } from "atomize"

import Layout from "../components/layout"
import SEO from "../components/seo"

import i404 from "../images/404.svg"
import "../css/global.css"
const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" />
    <Div tag="section" p={{ t: { xs: "6rem", md: "10rem" } }}>
      <Container d="flex" flexDir="column" align="center">
        <Text
          tag="h1"
          textWeight="500"
          textAlign="center"
          textSize={{ xs: "title", sm: "heading", md: "display1" }}
          fontFamily="secondary"
          m={{ b: "1rem" }}
        >
          404: Diese Seite existiert nicht.
        </Text>
        <Image src={i404} maxW={{ xs: "auto", md: "20rem" }} />
      </Container>
    </Div>
  </Layout>
)

export default NotFoundPage
